'use strict';

import { createPopper } from '@popperjs/core';
import Evented from './evented';

export default class TooltipHandler extends Evented {

  constructor( _id = 'tooltip', _class = 'tooltip', _style = false ) {

    super();

    this.id = _id;

    this.elt = null;
    this.items = [];
    this.beacon = null;

    this.template = '<div class="tooltip" role="tooltip"><div class="arrow" x-arrow></div><div class="inner"></div></div>';

    this.addInstance( _id, _class, _style );

  }

  getContent( reference ) {
    return reference.getAttribute( 'data-tooltip' ) ? reference.getAttribute( 'data-tooltip' ).replace( /\*(\w+)\*/g, "<strong>$1</strong>" ) : false;
  }

  addBeacon() {
    this.beacon = document.createElement( 'div' );
    this.beacon.id = `${this.id}-beacon`;
    this.beacon.style.position = 'absolute';
    this.beacon.style.width = '1px';
    this.beacon.style.height = '1px';
    this.beacon.style.pointerEvents = 'none';
    document.body.appendChild( this.beacon );
  }

  _self_show( e, reference ) {
    this._show( reference, this.elt );
  }

  _show( reference, whichTooltip = this.elt, content = false ) {

    if ( ! content ) {
      content = this.getContent( reference );
    }
    this.elt.querySelector( '.inner' ).innerHTML = content;

    this.popper = createPopper( reference, this.elt, {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: this.arrow,
          },
        },
      ],
      onFirstUpdate: ( data ) => {
        this.elt.classList.add( 'show' );
      },
    } );

  }
  _showOnBeacon( position, whichTooltip = this.elt, content = false ) {
    this.beacon.style.top = `${position.top}px`;
    this.beacon.style.left = `${position.left}px`;
    this._show( this.beacon, whichTooltip, content );
  }

  _hide( whichTooltip = this.elt ) {
    whichTooltip.classList.remove( 'show' );
  }

  _self_hide() {
    this._hide( this.elt );
  }

  updateSources( whichNode = document ) {

    // Transform titles > data-tooltips on request
    this.items = whichNode.querySelectorAll( '.tooltips *[title]' );

    this.items.forEach( ( item ) => {
      if ( item.getAttribute( 'title' ) ) {
        item.setAttribute( 'data-tooltip', item.getAttribute( 'title' ) );
      }
    } );

    // Registration
    this.items = document.querySelectorAll( '*[data-tooltip]' );
    this.items.forEach( ( item ) => {

      if ( ! item.hasAttribute( 'data-tooltip-a' ) && this.getContent( item ) ) {

        if ( item.getAttribute( 'title' ) ) {
          item.removeAttribute( 'title' );
        }

        item.setAttribute( 'data-tooltip-a', true );

        item.addEventListener( 'mouseover', ( e ) => {
          this._self_show( e, item );
        } );

        item.addEventListener( 'mouseout', () => {
          this._self_hide();
        } );

      }

    } );

  }

  addInstance( _id, _class, _style = false ) {

    if ( null !== document.getElementById( _id ) ) {
      return;
    }

    const tooltipGenerator = window.document.createElement( 'div' );
    tooltipGenerator.innerHTML = this.template.trim();
    this.elt = tooltipGenerator.childNodes[ 0 ];
    _class.split( ' ' ).forEach( ( c ) => {
      this.elt.classList.add( c );
    } );
    this.elt.id = _id;

    document.body.appendChild( this.elt );
    this.arrow = this.elt.querySelector( '.arrow' );

    if ( false !== _style ) {

      const head = document.head || document.getElementsByTagName( 'head' )[ 0 ];
      const style = document.createElement( 'style' );

      const basename = `.tooltip#${_id}`;

      const css =	`${basename} .inner { background: ${_style.background}; color: ${_style.color}; }
                  ${basename} .arrow { border-color: transparent transparent ${_style.background}; }`;

      style.type = 'text/css';
      if ( style.styleSheet ) {
        style.styleSheet.cssText = css;
      }
      else {
        style.appendChild( document.createTextNode( css ) );
      }

      head.appendChild( style );

    }

    return this.elt;

  }

  initDefault() {
    this.updateSources();
  };

}
