// @flow
'use strict';

( function() {

  const _f = {};

  if ( typeof exports != 'undefined' && ! exports.nodeType ) {
    if ( typeof module != 'undefined' && ! module.nodeType && module.exports ) {
      exports = module.exports = _f;
    }
    exports._f = _f;
  }
  else {
    root._f = _f;
  }

  _f.buildNode = ( string ) => {
    const template = window.document.createElement( 'div' );
    template.innerHTML = string;
    return Array.from( template.childNodes ).filter( node => Node.ELEMENT_NODE === node.nodeType )[ 0 ];
  }

  _f.sprintf = require( 'sprintf-js' ).sprintf;

  /**
   * Given a destination object and optionally many source objects,
   * copy all properties from the source objects into the destination.
   * The last source object given overrides properties from previous
   * source objects.
   *
   * @param dest destination object
   * @param {...Object} sources sources from which properties are pulled
   * @private
   */
  _f.extend = _f.deepObjectExtend = function( target = {}, ... source ) {

    for ( const i in source ) {

      const source_entry = source[ i ] || {};
      for ( const prop in source_entry ) {
        if ( prop in target && typeof target[ prop ] === 'object' && typeof source_entry[ prop ] === 'object' && target[ prop ].constructor !== Array ) {
          _f.extend( target[ prop ], source_entry[ prop ] );
        }
        else {
          target[ prop ] = source_entry[ prop ];
        }
      }

    }

    return target;

  };

  _f.uuid = function() {

    /*jslint bitwise: true */
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c ) => {
      const r = ( d + Math.random() * 16 ) % 16 | 0;
      d = Math.floor( d / 16 );
      return ( c === 'x' ? r : ( r & 0x3 | 0x8 ) ).toString( 16 );
    } );
    return uuid;
    /*jslint bitwise: false */

  };


  /**
   * Determine if a string ends with a particular substring
   *
   * @private
   */

  _f.endsWith = function( string, suffix ) {
    return string.indexOf( suffix, string.length - suffix.length ) !== -1;
  };

  _f.getScrollPosition = function() {
    return ( ( window.pageYOffset !== undefined ) ? window.pageYOffset : ( document.documentElement || document.body.parentNode || document.body ).scrollTop );
  };

  _f.easing = {

    // no easing, no acceleration
    linear: ( t ) => { return t; },
    // accelerating from zero velocity
    easeInQuad: ( t ) => { return t*t; },
    // decelerating to zero velocity
    easeOutQuad: ( t ) => { return t*( 2-t ); },
    // acceleration until halfway, then deceleration
    easeInOutQuad: ( t ) => { return t<0.5 ? 2*t*t : -1+( 4-2*t )*t; },
    // accelerating from zero velocity
    easeInCubic: ( t ) => { return t*t*t; },
    // decelerating to zero velocity
    easeOutCubic: ( t ) => { return ( --t )*t*t+1; },
    // acceleration until halfway, then deceleration
    easeInOutCubic: ( t ) => { return t<0.5 ? 4*t*t*t : ( t-1 )*( 2*t-2 )*( 2*t-2 )+1; },
    // accelerating from zero velocity
    easeInQuart: ( t ) => { return t*t*t*t; },
    // decelerating to zero velocity
    easeOutQuart: ( t ) => { return 1-( --t )*t*t*t; },
    // acceleration until halfway, then deceleration
    easeInOutQuart: ( t ) => { return t<0.5 ? 8*t*t*t*t : 1-8*( --t )*t*t*t; },
    // accelerating from zero velocity
    easeInQuint: ( t ) => { return t*t*t*t*t; },
    // decelerating to zero velocity
    easeOutQuint: ( t ) => { return 1+( --t )*t*t*t*t; },
    // acceleration until halfway, then deceleration
    easeInOutQuint: ( t ) => { return t<0.5 ? 16*t*t*t*t*t : 1+16*( --t )*t*t*t*t; }

  };


  _f.animate = ( item, attr, duration = 1400, easingFunction = _f.easing.easeInOutCubic, callback = () => {} ) => {

    const start = new Date().getTime();
    let currentTime;
    let time;
    let easedT;

    for ( const i in attr ) {
      attr[ i ] = {
        attr: i,
        set: ( -1 === [ 'scrollTop' ].indexOf( i ) ) ? item.style : item,
        to: attr[ i ],
        // from: parseInt( _f.getStyle( item )[ i ] ) || 0,
        unit: ( -1 === [ 'opacity', 'zIndex', 'scrollTop' ].indexOf( i ) ) ? 'px' : '',
      };
      attr[ i ].from = parseInt( attr[ i ].set[ i ] ) || 0;
    }

    const min = ( a, b ) => {
      return a < b ? a : b;
    }

    const animate = () => {

      currentTime = new Date().getTime();
      time = min( 1, ( ( currentTime - start ) / duration ) );
      easedT = easingFunction( time );

      for ( const i in attr ) {
        attr[ i ].set[ i ] = ( ( easedT * ( attr[ i ].to - attr[ i ].from ) ) + attr[ i ].from ) + attr[ i ].unit;
      }
      if ( time < 1 ) {
        requestAnimationFrame( animate );
      }
      else {
        if ( callback ) {
          callback();
        }
      }

    }

    requestAnimationFrame( animate );

  };
  _f.scrollTo = function( to, duration = 1400, easingFunction = _f.easing.easeInOutCubic, callback = () => {} ) {

    const elem = document.documentElement;
    const from = _f.getScrollPosition();

    if ( from === to ) {
      callback();
      return; /* Prevent scrolling to the Y point if already there */
    }

    _f.animate( elem, { 'scrollTop': to }, duration, easingFunction, callback );

  };


  _f.rgba = function( color, opacity ) {

    color = color.replace( '#', '' );
    return `rgba(${parseInt( color.substring( 0, 2 ), 16 )}, ${parseInt( color.substring( 2, 4 ), 16 )}, ${parseInt( color.substring( 4, 6 ), 16 )}, ${opacity})`;

  };

  _f.__style = false;

  _f.write_style = function( string ) {

    if ( false === _f.__style ) {

      const head = document.head || document.getElementsByTagName( 'head' )[ 0 ];
      const style = document.createElement( 'style' );
      style.type = 'text/css';
      _f.__style = head.appendChild( style );

    }

    if  ( _f.__style.styleSheet ) {
      _f.__style.styleSheet.cssText += string;
    }
    else {
      _f.__style.appendChild( document.createTextNode( string ) );
    }

  };

  _f.load = async ( src, method = 'get', data = null, headers = {} ) => {
    return new Promise( ( resolve, reject ) => {
      fetch( src, {
        method: method,
        body: data,
        headers: Object.assign( {
          'Accept': 'application/json',
        }, headers ),
      } )
      .then( res => res.text() )
      .then( res => resolve( res ) );
    } );
  };

} )();
