export default class LazyLoad {

  constructor() {

    const lazyImages = [].slice.call( document.querySelectorAll( 'img.lazy' ) );
    const lazyVideos = [].slice.call( document.querySelectorAll( '.video.lazy' ) );
    let initDone = false;

    if ( "IntersectionObserver" in window ) {

      // PICTURES

      const handleLazyBrowserResize = () => {
        // console.log('handleBrowserResize');
        // console.log(document.querySelectorAll( 'img.lazy' ));

        document.querySelectorAll( 'img.lazy' ).forEach( ( img ) => {
          const img_ratio = parseInt( img.getAttribute( 'width' ) ) / parseInt( img.getAttribute( 'height' ) );
          const container_ratio = parseInt( img.parentNode.offsetWidth ) / parseInt( img.parentNode.offsetHeight );

          // console.log( img_ratio, container_ratio );

          if ( img_ratio > container_ratio ) {
            img.classList.remove( 'fw' );
            img.classList.add( 'fh' );
          }
          else {
            img.classList.remove( 'fh' );
            img.classList.add( 'fw' );
          }
        } );
      };

      const lazyImageObserver = new IntersectionObserver( ( entries, observer ) => {
        entries.forEach( ( entry ) => {
          if ( entry.isIntersecting ) {
            this.lazyImageDisplay( entry.target );
            lazyImageObserver.unobserve( entry.target );
          }
        } );
      }, {
        rootMargin: '50px 0px 50px 0px',
      } );

      const mainLazyImageObserver = new IntersectionObserver( ( entries, observer ) => {

        if ( ! initDone ) {
          initDone = true;
          window.dispatchEvent( new CustomEvent( 'lazy-load-done' ) );
        }

        if ( entries[ 0 ].isIntersecting ) {

          _wp.c.d && console.debug( 'Got close to first lazy image. Starting main LazyLoad IntersectionObserver.' );
          mainLazyImageObserver.disconnect();

          lazyImages.forEach( ( lazyImage ) => {
            if ( lazyImage.dataset.hasOwnProperty( 'src' ) ) {
              const img = _f.buildNode( `<img src="${lazyImage.dataset.src}" class="lazy" width="${lazyImage.getAttribute( 'width' )}" height="${lazyImage.getAttribute( 'height' )}" alt="${lazyImage.getAttribute( 'alt' )}" />` );
              lazyImage.parentNode.insertBefore( img, lazyImage );

              lazyImage.classList.remove( 'lazy' );
              lazyImage.parentNode.classList.add( ( parseInt( lazyImage.getAttribute( 'width' ) ) > parseInt( lazyImage.getAttribute( 'height' ) ) ) ? 'dl' : 'dp' );
              lazyImageObserver.observe( lazyImage );
            }
          } );

          if ( document.body.classList.contains( 'photography-gallery' ) ) {
            handleLazyBrowserResize();
          }

        }

      }, {
        rootMargin: '99999px 0px 200px 0px',
      } );
      if ( null !== document.querySelector( '.lazy' ) ) {
        mainLazyImageObserver.observe( document.querySelector( '.lazy' ) );
      }

      // VIDEO

      const lazyVideoObserver = new IntersectionObserver( ( entries, observer ) => {
        entries.forEach( ( entry ) => {
          if ( entry.isIntersecting ) {
            this.lazyVideoDisplay( entry.target );
            lazyVideoObserver.unobserve( entry.target );
          }
        } );
      } );
      lazyVideos.forEach( ( lazyVideo ) => {
        lazyVideoObserver.observe( lazyVideo );
      } );

    }

  }

  lazyImageDisplay( image ) {

    const lazy = image.parentNode.querySelector( '.lazy' );

    image.onload = () => {
      lazy.classList.add( 'done' );
      setTimeout( () => { image.parentNode.removeChild( lazy ); }, 1000 );
    };

    image.srcset = image.dataset.srcset;
    delete image.dataset.srcset;
    image.sizes = image.dataset.sizes;
    delete image.dataset.sizes;

  }

  lazyVideoDisplay( video ) {
    video.appendChild( document.createRange().createContextualFragment( video.querySelector( 'noscript' ).childNodes[ 0 ].nodeValue ) );
  }

}
