'use strict';

import Rating from 'rating';

const tooltips = {
  'fr': [ 'Mouais', 'Passable', `C'était OK`, 'Bien bien!', 'Adoré!' ],
  'en': [ 'Meh', 'So-so', 'That was OK', 'Good good!', 'Loved it!' ],
};

export default class RatingsForm {

  constructor( node ) {

    if ( ! node ) {
      return;
    }

    const rating = new Rating( [ 1, 2, 3, 4, 5 ] );
    rating.set( Math.round( node.dataset.rating ) );
    node.appendChild( rating.el );

    node.querySelectorAll( 'span.star' ).forEach( ( span, index ) => {
      span.dataset.tooltip = tooltips[_wp.l][index];
    } );

    window._tooltip.updateSources( node );

    rating.on( 'rate', async ( weight ) => {

      const _data = new FormData();
      _data.append( 'action', 'post_rating' );
      _data.append( 'id', node.dataset.ratingId );
      _data.append( 'rating', weight );

      try {
        const result = await _f.load(
          _wp.c.au,
          'post',
          _data
        );
        _wp.c.d && console.log( 'Ratings registered' );
        _wp.c.d && console.log( result );
        window.dataLayer.push( {
          event: 'ratingVote',
          value: weight,
        } );
      }
      catch ( e ) {
        console.error( 'Rating error!' );
        _wp.c.d && console.error( e );
      }

    } );

  }

}
