import { createPopper } from '@popperjs/core';
import Evented from './evented';
import _f from './functions';

export default class PictureCopyrightTooltipHandler extends Evented {

  constructor() {

    super();

    this.elt = null;
    this.items = [];
    this.openItem = false;
    this.popper = false;
    this.watcher = false;

    this.template = '<div class="tooltip copyright" role="tooltip"><div class="inner"></div></div>';

    this.init();

  }

  getContent( item ) {

    if ( undefined !== item.dataset.license ) {
      if ( false !== item.dataset.license.indexOf( 'getty' ) ){
        return _f.buildNode( _f.sprintf( `<p>${ _wp.js.copy.getty }</p>`, item.dataset.license ) );
      }
      return _f.buildNode( _f.sprintf( `<p>${ _wp.js.copy.license }</p>`, item.dataset.license ) );
    }
    return _f.buildNode( `<p>${ _wp.js.copy.std }</p>` );

  }

  showTooltip( reference, e ) {

    e.preventDefault();
    e.stopPropagation();

    const inner = this.elt.querySelector( '.inner' );

    while ( inner.hasChildNodes() ) {
      inner.removeChild( inner.lastChild );
    }

    inner.appendChild( this.getContent( reference ) );

    this.popper = createPopper( {
      getBoundingClientRect: () => {
        return {
          width: 0,
          height: 0,
          left: e.clientX,
          top: e.clientY,
          right: e.clientX,
          bottom: e.clientY,
        };
      }
    }, this.elt, {
      strategy: 'fixed',
      onFirstUpdate: ( data ) => {
        this.elt.classList.add( 'show' );
      },
    } );

    if ( ! this.openItem ) {
      this.watcher = ( ( e ) => {
          this.maybeHide( e );
      } );
      window.document.addEventListener( 'click', this.watcher );
    }
    else {
      this.elt.classList.remove( 'open' );
      if ( reference === this.openItem ) {
        this.hideTooltip();
        return;
      }
    }

    this.openItem = reference;

  }

  maybeHide( e ) {

    const eltRect = this.elt.getBoundingClientRect();

    if ( ! ( e.clientX >= eltRect.left && e.clientY >= eltRect.top && e.clientX <= eltRect.left + eltRect.width && e.clientY <= eltRect.top + eltRect.height ) ) {
      this.hideTooltip();
    }

  }

  hideTooltip() {

    this.popper.destroy();
    this.elt.classList.remove( 'show' );
    this.openItem.classList.remove( 'open' );
    window.document.removeEventListener( 'click', this.watcher );
    this.openItem = false;

  }

  init() {

    if ( ! this.elt ) {

      const tooltipGenerator = window.document.createElement( 'div' );
      tooltipGenerator.innerHTML = this.template.trim();
      this.elt = tooltipGenerator.childNodes[ 0 ];
      document.body.appendChild( this.elt );

    }

    // Registration

    this.items = document.querySelectorAll( '.pictbox img' );
    this.items.forEach( ( item ) => {
      item.addEventListener( 'contextmenu', this.showTooltip.bind( this, item ) );
      item.addEventListener( 'dragstart', this.showTooltip.bind( this, item ) );
    } );

  }

  addOnElement( domElement ) {
    domElement.addEventListener( 'contextmenu', this.showTooltip.bind( this, domElement ) );
    domElement.addEventListener( 'dragstart', this.showTooltip.bind( this, domElement ) );
  }

}
