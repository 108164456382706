"use strict";

import { createPopper } from '@popperjs/core';
import Evented from './evented';

export default class ReferenceTooltips extends Evented {

  constructor() {

    super();

    this.elt = null;
    this.items = [];
    this.openItem = false;
    this.popper = false;
    this.watcher = false;

    this.template = '<div class="tooltip reference" role="tooltip"><div class="arrow" x-arrow></div><div class="inner"></div></div>';

    this.init();

  }

  getContent( item ) {

    if ( ( item.getAttribute( 'href' ) ) && '#' === item.getAttribute( 'href' ).charAt( 0 ) ) {
      for ( let i = 0, nodes = document.getElementById( item.getAttribute( 'href' ).substr( 1 ) ).children ; i < nodes.length ; i++ ) {
        if ( nodes[ i ].classList.contains( 'ref-content' ) ) {
          return nodes[ i ].cloneNode( true );
        }
      }
      if ( document.querySelector( `#${item.getAttribute( 'href' ).substr( 1 )} .reference-content` ) ) {
        return document.querySelector( `#${item.getAttribute( 'href' ).substr( 1 )} .reference-content` ).childNodes[ 0 ].cloneNode( true );
      }
    }

    return false;

  }

  showTooltip( e, reference ) {

    const inner = this.elt.querySelector( '.inner' );

    while ( inner.hasChildNodes() ) {
      inner.removeChild( inner.lastChild );
    }

    inner.appendChild( this.getContent( reference ) );

    this.popper = createPopper( reference, this.elt, {
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: this.arrow,
          },
        },
      ],
      onFirstUpdate: ( data ) => {
        this.elt.classList.add( 'show' );
      },
    } );

    reference.classList.add( 'open' );

    if ( ! this.openItem ) {
      this.watcher = ( ( e ) => {
          this.maybeHide( e );
      } );
      window.document.addEventListener( 'click', this.watcher );
    }
    else {
      this.elt.classList.remove( 'open' );
      if ( reference === this.openItem ) {
        this.hideTooltip();
        return;
      }
    }

    this.openItem = reference;

  }

  maybeHide( e ) {

    const eltRect = this.elt.getBoundingClientRect();

    if ( ! ( e.clientX >= eltRect.left && e.clientY >= eltRect.top && e.clientX <= eltRect.left + eltRect.width && e.clientY <= eltRect.top + eltRect.height ) ) {
      this.hideTooltip();
    }

  }

  hideTooltip() {

    this.popper.destroy();
    this.elt.classList.remove( 'show' );
    this.openItem.classList.remove( 'open' );
    window.document.removeEventListener( 'click', this.watcher );
    this.openItem = false;

  }

  init() {

    if ( ! this.elt ) {
      const tooltipGenerator = window.document.createElement( 'div' );
      tooltipGenerator.innerHTML = this.template.trim();
      this.elt = tooltipGenerator.childNodes[ 0 ];

      document.body.appendChild( this.elt );

      this.arrow = this.elt.querySelector( '.arrow' );
    }

    // Registration

    this.items = document.querySelectorAll( 'sup[id^=ref] a' );

    this.items.forEach( ( item ) => {
      if ( this.getContent( item ) ) {
        item.addEventListener( 'click', ( e ) => {
          e.stopPropagation();
          e.preventDefault();
          this.showTooltip( e, item );

        } );
      }
    } );

  }

}
