'use strict';

const _ = {
  debounce: require( 'lodash/debounce' ),
};

import Evented from './evented';

export default class FormsHandler extends Evented {

  constructor() {

    super();

    this.activateElasticTextareas();
    this.activateAjaxForms();

  };

  activateElasticTextareas() {

    const containsText = ( value ) => {
      return ( value.replace( /\s/g, '' ).length > 0 );
    };

    document.querySelectorAll( 'textarea' ).forEach( ( item ) => {

      if ( containsText( item.value ) ) {
        item.height = `${item.scrollHeight}px`;
      }

      item.addEventListener( 'input', () => {
        item.style.height = 'auto';
        item.style.height = `${item.scrollHeight}px`;
      } );

    } );

  }

  addRecaptcha() {

    _wp.c.d && console.debug( `Adding recaptcha` );

    window.ajaxFormsCaptchaCallback = this.finalizeAjaxFormsWithRecaptcha;

    const head = document.getElementsByTagName( 'head' )[0];
    const script = document.createElement( 'script' );
    script.type = 'text/javascript';
    script.src = `//www.google.com/recaptcha/api.js?onload=ajaxFormsCaptchaCallback&render=explicit&hl=${_wp.l}`;
    head.appendChild( script );

  }

  finalizeAjaxFormsWithRecaptcha() {

    _wp.c.d && console.debug( `-- finalizeAjaxFormsWithRecaptcha` );

    const forms = document.querySelectorAll( 'form' );

    forms.forEach( ( form, index ) => {

      const btn = form.querySelector( '.progress-button' );
      const message = form.querySelector( '.form-message' );

      if ( ! message ) {
        return
      }
      if ( ! btn ) {
        return;
      }

      const captchaResponse = window.document.createElement( 'div' );
      captchaResponse.className = 'captcha-response';
      message.parentNode.insertBefore( captchaResponse, message.nextSibling );

      const captcha = grecaptcha.render( form.querySelector( '.captcha-response' ), {
        sitekey: '6LeKGH8UAAAAADVagutrQ1lYtb2zNRBes82FVoke',
        size: 'invisible',
        badge: 'inline',
        callback: `cbCaptcha${index}`,
      } );

      let attempt = 0;

      const input = btn.querySelector( 'button' );

      const callback = ( rawdata ) => {

        let data;
        try {
          data = JSON.parse( rawdata );
        }
        catch ( e ) {
          data = rawdata;
        }

        let d = 0;

        if ( data.hasOwnProperty( 'status' ) && 'error' === data.status ) {

          window.dataLayer.push( {

          } );

          btn.classList.remove( 'loading' );
          btn.classList.add( 'error' );

          if ( data.hasOwnProperty( 'message' ) && undefined !== message ) {
            message.classList.add( 'visible' );
            message.innerHTML = data.message;
          }

          setTimeout( resetButton, 3000 );

          for ( const key in data.errors ) {
            // const item = data.errors[ key ];
            form.querySelector( `.form-group.${key}` ).classList.add( 'has-warning' );
            form.querySelector( `.form-group.${key}` ).classList.add( `d${d}` );
            form.querySelector( `.form-group #${key}` ).classList.add( 'form-control-warning' );
            d++;
          }

        }
        else if ( data.hasOwnProperty( 'status' ) && 'success' === data.status ) {

          // window.dataLayer.push( {

          // } );

          btn.classList.remove( 'loading' );
          btn.classList.add( 'success' );

          if ( data.hasOwnProperty( 'message' ) && ( message ) ) {
            message.classList.add( 'visible' );
            message.classList.add( 'success' );
            message.innerHTML = data.message;
          }

          resetForm();
          // setTimeout( resetButton, 3000 );

        }
        else { // Unhandled Error

          btn.classList.remove( 'loading' );
          btn.classList.add( 'error' );

          if ( data.hasOwnProperty( 'message' ) && undefined !== message ) {
            message.classList.add( 'visible' );
            message.innerHTML = data.message;
          }

          setTimeout( resetButton, 3000 );

          data.errors.forEach( ( item, key ) => {
            form.querySelector( `.form-group.${key}` ).classList.add( 'has-warning' );
            form.querySelector( `.form-group.${key}` ).classList.add( `d${d}` );
            form.querySelector( `.form-group #${key}` ).classList.add( 'form-control-warning' );
            d++;
          } );

        }

      };

      const captchaCallback = async ( token ) => {

        _wp.c.d && console.log( '-- captchaCallback', token );

        const extraData = { attempt: ++attempt };
        const data = new FormData( form );
        if ( undefined !== extraData ) {
          for ( const i in extraData ) {
            data.append( i, extraData[ i ] );
          }
        }
        data.append( 'captcha', token );
        const result = await _f.load( form.getAttribute( 'action' ), 'post', data );
        callback( result );

      }

      const submit = () => {

        _wp.c.d && console.debug( 'submit - execute captcha' );

        grecaptcha.reset();
        grecaptcha.execute( captcha );

      };

      const resetButton = () => {

        input.removeAttribute( 'disabled' );
        btn.classList.remove( 'error' );
        btn.classList.remove( 'success' );
        btn.classList.remove( 'loading' );

      };

      const resetForm = () => {

        form.querySelectorAll( 'textarea, input' ).forEach( ( item ) => {
          item.setAttribute( 'disabled', 'disabled' );
        } );

      };

      const submitThrottle = _.debounce( submit, 800 );

      window[ `cbCaptcha${index}` ] = captchaCallback;

      form.addEventListener( 'submit', ( e ) => {

        e.preventDefault();

        _wp.c.d && console.log( 'Submit form' );

        form.querySelectorAll( '.form-group' ).forEach( ( item ) => {
          item.classList.remove( 'has-warning' );
          item.classList.remove( 'd0' );
          item.classList.remove( 'd1' );
          item.classList.remove( 'd2' );
        } );
        form.querySelectorAll( 'input, textarea' ).forEach( ( item ) => {
          item.classList.remove( 'form-control-warning' );
        } );

        btn.classList.remove( 'success' );
        btn.classList.remove( 'error' );
        btn.classList.add( 'loading' );
        input.setAttribute( 'disabled', 'disabled' );

        message.classList.remove( 'visible' );

        submitThrottle();

      } );

    } );

  }

  activateAjaxForms() {

    if ( ! document.querySelector( '.progress-button' ) ) {
      return;
    }

    const observer = new IntersectionObserver( ( entries, observer ) => {
      if ( entries[ 0 ].isIntersecting ) {
        observer.disconnect();
        this.addRecaptcha();
      }
    }, {
      rootMargin: '100px 0px 100px 0px',
    } );
    observer.observe( document.querySelector( '.progress-button' ) );

  }

}
