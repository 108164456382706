'use strict';

require( 'intersection-observer' );

import Evented from './modules/evented';
import _f from './modules/functions';
import LazyLoad from './modules/lazy-load';
import ReferenceTooltipsHandler from './modules/reference-tooltips';
import FormsHandler from './modules/form';
import Ratings from './modules/ratings';
import TooltipsHandler from './modules/tooltips';
import CopyrightTooltipHandler from './modules/picture-copyright-tooltip';
import PhotographyGallery from './modules/photography-gallery';

import Flickity from 'flickity';
import Fuse from 'fuse.js';

import mediumZoom from 'medium-zoom';

const _ = {
  templateSettings: require( 'lodash/templateSettings' ),
  template: require( 'lodash/template' ),
  throttle: require( 'lodash/throttle' ),
};

const THEME = 'v7';

export default class UI extends Evented {

  constructor() {

    super();

    window._f = _f;
    window.dataLayer = window.dataLayer || [];
    window.mediumZoom = mediumZoom;

  }

  toggleMenu() {
    document.documentElement.classList.toggle( 'nav-open' );
    this.updateMenuA11y();
  }
  closeMenu() {
    document.documentElement.classList.remove( 'nav-open' );
    this.updateMenuA11y();
  }
  updateMenuA11y() {
    if ( document.documentElement.classList.contains( 'nav-open' ) ) {
      document.getElementById( 'opener' ).setAttribute( 'aria-expanded', true );
    }
    else {
      document.getElementById( 'opener' ).setAttribute( 'aria-expanded', false );
    }
  }

  earlyInit() {

    _wp.c.d && console.log( 'UI::earlyInit' );

    document.documentElement.classList.add( 'ready' );
    document.getElementById( 'opener' ).addEventListener( 'click', this.toggleMenu.bind( this ) );

    document.querySelectorAll( '#toc li a' ).forEach( ( link ) => {
      if ( '#' !== link.getAttribute( 'href' ) ) {
        link.addEventListener( 'click', this.closeMenu.bind( this ) );
        const block = document.querySelector( link.getAttribute( 'href' ) );
        if ( block ) {
          const id = block.getAttribute( 'id' );
          block.removeAttribute( 'id' );
          block.parentNode.insertBefore( _f.buildNode( `<a id="${id}" />` ), block );
        }
      }
      else {
        const li = link.parentNode;
        li.parentNode.removeChild( li );
      }
    } );

  }

  lateInit() {

    _wp.c.d && console.log( 'UI::lateInit' );

    window.addEventListener( 'lazy-load-done', () => {

      const carouselDom = document.querySelector( '.carousel' );

      if ( null !== carouselDom ) {

        const flkty = new Flickity( carouselDom, {
          // options
          wrapAround: true,
          cellSelector: 'figure.wp-block-berengerzyla-photo',
          pageDots: false,
          dragThreshold: 3,
          selectedAttraction: .1,
          friction: .6,
          setGallerySize: false,
        } );

        carouselDom.appendChild( _f.buildNode( `<span class="timer"></span>` ) );
        const timer = carouselDom.querySelector( '.timer' );

        carouselDom.dataset.slideIndex = 0;
        carouselDom.dataset.slideName = [ ...carouselDom.querySelector( 'figure.wp-block-berengerzyla-photo' ).classList ].filter( classname => 0 === classname.indexOf( 'slide-' ) ).toString();

        carouselDom.querySelectorAll( '.flickity-button-icon' ).forEach( svg => {
          svg.setAttribute( 'focusable', false );
        } );

        const imgs = carouselDom.querySelectorAll( 'img' );
        const docStyle = document.documentElement.style;
        const transformProp = typeof docStyle.transform == 'string' ? 'transform' : 'WebkitTransform';

        flkty.on( 'change', ( index ) => {
          carouselDom.dataset.slideIndex = index;
          carouselDom.dataset.slideName = [ ...flkty.slides[ index ].cells[ 0 ].element.classList ].filter( classname => 0 === classname.indexOf( 'slide-' ) ).toString();
          timer.classList.remove( 'start' );
          setTimeout( () => {
            timer.classList.add( 'start' );
          }, 500 )
        } );
        flkty.on( 'scroll', ( progress ) => {
          flkty.slides.forEach( ( slide, i ) => {
            const img = imgs[ i ];
            let x = ( slide.target + flkty.x ) * -1/3;

            if ( imgs.length - 1 === i && progress < 0 ) {
              x = ( slide.target - flkty.slidesWidth + flkty.x - flkty.slides[ i ].outerWidth ) * -1/3;
            }
            else if ( 0 === i && progress > 1 ) {
              x = ( flkty.slidesWidth + flkty.x + flkty.slides[ flkty.slides.length - 1 ].outerWidth + slide.target ) * -1/3;
            }

            img.style[ transformProp ] = 'translateX(' + x  + 'px)';
          } );
        } );

        timer.addEventListener( 'animationend', () => {
          timer.classList.remove( 'start' );
          setTimeout( () => {
            flkty.next( true, false );
          }, 500 );
        } );

        timer.classList.add( 'start' );

      }

    } );

    new LazyLoad();

    this.contentContainer = document.querySelector( '.entry-content' );

    if ( this.contentContainer ) {
      window.addEventListener( 'scroll', this.updateScrollOffset.bind( this ) );
      this.updateScrollOffset();
    }

    document.querySelectorAll( 'a.pictbox[href]' ).forEach( ( elt ) => {
      elt.querySelector( 'img' ).dataset.zoomSrc = elt.href;
      elt.removeAttribute( 'href' );
    } );
    document.querySelectorAll( 'a[rel="shadowbox"][href]' ).forEach( ( elt ) => {
      elt.querySelector( 'img' ).dataset.zoomSrc = elt.href;
      elt.classList.add( 'pictbox' );
      elt.removeAttribute( 'href' );
    } );

    const zoom = mediumZoom( 'a.pictbox img[data-zoom-src]', {
      margin: 24,
    } );
    const hostnameRemover = new RegExp( `https?:${_wp.c.cdn}`, 'gi' );
    zoom.on( 'opened', ( e ) => {
      window.dataLayer.push( {
        event: 'imageZoom',
        imageZoomSrc: e.detail.zoom.getZoomedImage().dataset.zoomSrc.replace( hostnameRemover, '' ),
      } );
      document.querySelectorAll( '.medium-zoom-image--opened' ).forEach( img => {
        window._copyrightTooltip.addOnElement( img );
      } )
    } );

    this.searchEngine();

    window.dispatchEvent( new CustomEvent( 'late-init-done' ) );

    setTimeout( _ui.veryLateInit.bind( _ui ), 2000 );

  }

  veryLateInit() {

    _wp.c.d && console.log( 'UI::veryLateInit' );

    window._tooltipsHandler = TooltipsHandler;
    window._tooltip = new window._tooltipsHandler();
    window._tooltip.initDefault();
    new ReferenceTooltipsHandler();
    window._copyrightTooltip = new CopyrightTooltipHandler();

    const ratings = document.getElementById( 'rating-mod' );
    if ( ratings ) {
      new Ratings( ratings );
    }

    new FormsHandler();

    if ( document.body.classList.contains( 'photography-gallery' ) ) {
      new PhotographyGallery();
    }

    // Smooth scroll
    const handleSmoothScroll = ( e ) => {
      e.preventDefault();
      const id = e.currentTarget.href.substring( e.currentTarget.href.indexOf( '#' ) + 1 );
      _f.animate( document.documentElement, { 'scrollTop': document.getElementById( id ).getBoundingClientRect().top - 20 }, 1400 );
    }
    document.querySelectorAll( '*[href^="#"]' ).forEach( link => link.addEventListener( 'click', handleSmoothScroll ) );

  }

  updateScrollOffset() {
    document.body.setAttribute( 'data-offset', ( window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop ) / Math.max( document.documentElement.clientHeight, window.innerHeight || 0 ) );
    document.body.style.setProperty( '--data-offset', ( window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop ) / Math.max( document.documentElement.clientHeight, window.innerHeight || 0 ) );
    document.body.style.setProperty( '--content-data-offset', Math.max( 0, Math.min( 1, ( this.contentContainer.getBoundingClientRect().height - this.contentContainer.getBoundingClientRect().bottom ) / this.contentContainer.getBoundingClientRect().height ) ) );
  }

  async searchEngine() {

    let data = [];
    if ( window.localStorage ) {
      const fetched = window.localStorage.getItem( `search-data-${_wp.l}-fetched` );
      if ( fetched && '' !== fetched && Date.parse( fetched ) > ( new Date().getTime() - 1000 * 60 * 60 * 24 * 7 ) ) {
        data = window.localStorage.getItem( `search-data-${_wp.l}` );
      }
    }

    if ( ! Array.isArray( data ) || 0 === data.length ) {
      data = await fetch( `/wp-content/uploads/search-data-${_wp.l}.json` )
              .then( response => response.json() )
              .then( ( json ) => {
                window.localStorage.setItem( `search-data-${_wp.l}`, JSON.stringify( json ) );
                window.localStorage.setItem( `search-data-${_wp.l}-fetched`, new Date() );
                return json;
              } )
              .catch( ( e ) => { console.error( e ); } );
    }

    if ( ! Array.isArray( data ) || 0 === data.length ) {
      return;
    }

    let autocompleteData = [];
    let saveInLocalStorage = false;
    if ( window.localStorage && window.localStorage.getItem( 'autocompleteDataExpiration' ) && parseInt( window.localStorage.getItem( 'autocompleteDataExpiration' ) ) > new Date().getTime() && window.localStorage.getItem( 'autocompleteData' ) ) {
      try {
        autocompleteData = JSON.parse( window.localStorage.getItem( 'autocompleteData' ) );
      }
      catch ( e ) {
        console.error( e );
      }
    }

    if ( ! autocompleteData || 0 === autocompleteData.length ) {
      for ( const i in data ) {
        autocompleteData.push( ...data[ i ].taxonomies.filter( tax => '' !== tax ) );
      }
      autocompleteData = [ ...new Set( autocompleteData ) ];
      saveInLocalStorage = true;
    }

    if ( saveInLocalStorage ) {
      window.localStorage.setItem( 'autocompleteData', JSON.stringify( autocompleteData ) );
      window.localStorage.setItem( 'autocompleteDataExpiration', new Date().getTime() + 1000 * 60 * 60 * 24 * 7 );
      _wp.c.d && console.log( 'save autocompleteData in localStorage' );
    }

    const resultOptions = {
      shouldSort: true,
      includeScore: true,
      includeMatches: true,
      minMatchCharLength: 1,
      keys: [
        { name: 'title', weight: 1 },
        { name: 'permalink', weight: 1 },
        { name: 'headline', weight: .2 },
        { name: 'keywords', weight: .2 },
        { name: 'breadcrumb', weight: .4 },
        { name: 'taxonomies', weight: .6 },
      ]
    };
    resultOptions.keys.forEach( key => key.weight /= 10 );
    const fuseResults = new Fuse( data, resultOptions );

    const autocompleteOptions = {
      shouldSort: true,
      includeScore: true,
      minMatchCharLength: 1,
    };
    const fuseAutocomplete = new Fuse( autocompleteData, autocompleteOptions );

    _.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
    const resultTemplate = _.template( document.querySelector( '#tmpl-search-result' ).innerText.trim() );

    //

    // const overlay = document.querySelector( '.search-form-layover' );
    [ ...document.querySelectorAll( 'form[role="search"]' ) ].map( form => {

      // Log
      let previousSearchTerm = '';
      const logSearch = () => {

        const term = form.querySelector( '.search-field' ).value;
        if ( '' !== term && previousSearchTerm !== term ) {
          window.dataLayer.push( {
            event: 'customSearch',
            searchInput: term,
            searchLocation: form.dataset.location,
          } );
        }

      };
      const throttledLogSearch = _.throttle( logSearch.bind( this ), 2000 );

      // Quick results
      let resultsDom;
      if ( form.querySelector( '.search-form-results' ) ) {
        resultsDom = form.querySelector( '.search-form-results' ).appendChild( _f.buildNode( _.template( document.querySelector( '#tmpl-search-result-wrap' ).innerText.trim() )() ) ).querySelector( 'ol' );
      }
      const autocompleteDom = form.querySelector( 'datalist' );
      if ( autocompleteDom && ! form.querySelector( '.search-form-results' ) ) {
        form.querySelector( '.search-field' ).addEventListener( 'change', ( e ) => {
          console.log( e );
          if ( e instanceof Event ) {
            form.submit();
          }
        } );
      }

      const search = ( onInputChange = true ) => {
        const term = form.querySelector( '.search-field' ).value;

        if ( onInputChange ) {
          throttledLogSearch();
        }

        if ( '' !== term ) {
          if ( previousSearchTerm !== term ) {
            previousSearchTerm = term;
            const autocompleteResults = fuseAutocomplete.search( term ).slice( 0, 8 );
            const results = fuseResults.search( term ).slice( 0, 8 );
            // console.log('autocompleteResults', autocompleteResults);
            // console.log('results', results);

            if ( resultsDom ) {
              if ( 0 !== results.length ) {
                resultsDom.innerHTML = '';
                results.forEach( ( res ) => {
                  resultsDom.appendChild( _f.buildNode( resultTemplate( res.item ) ) );
                } );
                return;
              }
              else {
                resultsDom.innerHTML = `<li class="col nothing-found"><h3>${ 'fr' === _wp.l ? "Rien trouvé :(<br>Essayez de modifier votre recherche." : "Nothing found :(<br>Try to modify your search terms." }</h3></li>`;
              }
            }

            autocompleteDom.innerHTML = '';
            if ( 0 !== autocompleteResults.length ) {
              autocompleteResults.forEach( autocomplete => {
                const option = document.createElement( 'option' );
                option.value = autocomplete.item;
                autocompleteDom.appendChild( option );
              } );
            }
          }
        }
        else {
          if ( resultsDom ) {
            resultsDom.innerHTML = '';
          }
        }
      };

      form.querySelector( '.search-field' ).addEventListener( 'focus', () => {
        _f.animate( document.getElementById( 'access' ), { 'scrollTop': document.getElementById( 'access' ).scrollTop + form.getBoundingClientRect().top - 20 }, 1400 );
      } );
      form.querySelector( '.search-field' ).addEventListener( 'keyup', search );
      form.querySelector( '.search-field' ).addEventListener( 'keydown', search );

      if ( '' !== form.querySelector( '.search-field' ).value ) {
        previousSearchTerm = form.querySelector( '.search-field' ).value;
        search( false );
      }

    } );

  }

}

( function() {

  const _ui = new UI();
  window._ui = _ui;
  _ui.earlyInit();
  window.onload = _ui.lateInit.bind( _ui );

} )();
