import _f from './functions';

const _ = {
  debounce: require( 'lodash/debounce' ),
};

// KEY UP / KEY DOWN

export default class PhotographyGallery {

  constructor() {

    this.map = [];

    this.isScrolling = false;
    this.autoScroll = false;
    this.startingPos = _f.getScrollPosition();
    this.debouncedFinishedScrolling = _.debounce( this.finishedScrolling.bind( this ), 500 );
    this.debouncedUpdatePositionsMap = _.debounce( this.updatePositionsMap.bind( this ), 500 );

    window.addEventListener( 'scroll', this.handleScrolling.bind( this ) );
    window.addEventListener( 'resize', this.debouncedUpdatePositionsMap.bind( this ) );

    this.updatePositionsMap();

  }

  handleScrolling( e ) {

    if ( ! this.isScrolling ) {
      this.isScrolling = true;
      this.startingPos = _f.getScrollPosition();
    }
    this.debouncedFinishedScrolling();

  }
  finishedScrolling() {

    this.isScrolling = false;

    if ( this.autoScroll ) {
      this.autoScroll = false;
      return;
    }
    if ( _f.getScrollPosition() < 100 ) {
      return;
    }

    let which = null;

    if ( this.startingPos - _f.getScrollPosition() < 0 ) { // GOooing down!

      this.map.forEach( ( entry ) => {
        if ( entry.start < _f.getScrollPosition() + window.innerHeight / 2 ) {
          which = entry;
        }
      } );

      if ( which === this.map[ this.map.length - 1 ] && which.third < _f.getScrollPosition() ) {
        which = null;
      }

    }
    else {

      this.map.forEach( ( entry ) => {
        if ( entry.start < _f.getScrollPosition() + window.innerHeight / 2 ) {
          which = entry;
        }
      } );

    }

    if ( null !== which ) {
      this.autoScroll = true;
      _f.scrollTo( which.start );
    }

  }

  updatePositionsMap() {

    this.map = [];
    document.querySelectorAll( '.entry-content .mc' ).forEach( ( entry, i ) => {
      this.map.push( {
        index: i,
        start: parseInt( entry.getBoundingClientRect().top + _f.getScrollPosition() ),
        end: parseInt( entry.getBoundingClientRect().top + _f.getScrollPosition() + entry.getBoundingClientRect().height )
      } );
    } );

    this.map = this.map.map( ( entry ) => {
      entry.third = parseInt( entry.start + ( entry.end - entry.start ) / 3 );
      entry.half = parseInt( entry.start + ( entry.end - entry.start ) / 2 );
      return entry;
    } );

  }

}
